<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">QR Kodum</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">
          QR kodunuzu kütüphanedeki ekrana okutarak kitap alma işlemini gerçekletirebilirsiniz.
        </span>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <div style="text-align: center" class="p-5">
      <qrcode-vue :value="currentUser.qrCode" :size="300" level="H" />
    </div>
    <div class="card-footer">

      <div class="alert alert-success" role="alert">
        • Bu QR Kodunu tablete okutarak kitap ödünç alabilirsiniz.
      </div>
      <div class="alert alert-success" role="alert">
        • Sol üstteki menüyü kullanarak daha önce aldığınız kitapların bulunduğu sayfaya gidebilirsiniz.
      </div>
      <div class="alert alert-success" role="alert">
        • Aldığınız kitapların iade tarihini uzatabilir ve kitaplar hakkında yorum yapabilirsiniz.
      </div>
      <div class="alert alert-success" role="alert">
        • Kütüphanemizde bulunan tüm kitapları listeleyip arama yapabilirsiniz.
      </div>
      <div class="alert alert-success" role="alert">
        • Rafta olmayan kitapları istek listenize ekleyebilirsiniz. Böylelikle kitap kütüphaneye getirildiğinde bildirim alabilirsiniz.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import QrcodeVue from 'qrcode.vue'

export default {
  components: {QrcodeVue},
  data() {
    return {
      submitting: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'project.profile' },
      { title: 'project.qrCode' }
    ]);
  },
  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>
